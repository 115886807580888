body {
    overflow-x: hidden !important;
}

.nk-sidebar-brand {
    flex-shrink: 1;

    .logo-img {
        max-width: 11rem;
    }
}

.user-invests-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;
        flex-wrap: wrap;

        .inner-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;

            span {
                text-align: center;

                &:not(&:first-child) {
                    color: #fff;
                    font-size: 1rem;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #6576FF;
    border: 0px none #ffffff;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: #6576FF;
}

::-webkit-scrollbar-track {
    background: #adb6ff;
    border: 0px none #ffffff;
    border-radius: 2px;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
